import * as React from "react"
import PropTypes from "prop-types"
import SiteNavigation from "./nav-new/nav"
import Footer from "./footer-new/footer"
import "./layout.scss"
import IEBanner from "./ie-banner/ie-banner"
import FooterV2 from "../componentsv2/footerv2/footerv2"

const Layout = ({ children, nav, parentClass }) => {
  return (
    <>
      <IEBanner />
      {nav !== "hidden" && <SiteNavigation />}
      <main className={`spz-2023 ${parentClass}`}>{children}</main>
      {parentClass !== "newtheme2024" ? <Footer /> : ``}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
